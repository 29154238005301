import { useState } from 'react';
import { getUserTranscriptionLogs } from 'src/api/AnalyticsApi';
import moment from "moment";

const useFetchCSVData = ({seletedTimeRange}) => {
  const [loading, setLoading] = useState(false);

  const fetchCSVData = async () => {
    try {
      setLoading(true);
      let params = {
        start_date: moment(seletedTimeRange.startDate).format('YYYY-MM-DD'),
        end_date: moment(seletedTimeRange.endDate).format('YYYY-MM-DD')
      }
      const response = await getUserTranscriptionLogs(params);
      // Create a URL for the blob and trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'user_transcriptions.txt');
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error fetching CSV data:', error);
    } finally {
      setLoading(false);
    }
  };

  const FetchFullTranscript = async () =>{
    try {
    const response = await getUserTranscriptionLogs();
      // Create a URL for the blob and trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'user_transcriptions.txt');
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error fetching CSV data:', error);
    } finally {
      setLoading(false);
    }
  }

  return { loading, fetchCSVData, FetchFullTranscript };
};

export default useFetchCSVData;
