import React from 'react';
import style from './Quotations.module.scss';
import PieChartStats from '../Charts/PieChart';
import { Empty } from 'antd';

const COLORS = ['#0088FE', '#00C49F'];

const ProductSearchAnalytics = ({ data }) => {
  const chartData = [
    { name: 'Visitors Routed to Product Page', value: data?.users_routed_products || 0 },
    { name: 'Visitors Didn\'t Routed to Product Page', value: data?.users_not_routed_to_products || 0 },
  ];
  const percentageOfUsersRoutedToProducts = data?.percentage_of_users_routed_to_products

  const isDataEmpty = chartData[0].value === 0 && chartData[1].value === 0;
  return (
    <div className={style.statistics}>
      <div className={style.statItem}>
        <div>
          <h3 className={style.chartHeader}>NO. OF VISITORS WHO VISITED PRODUCT PAGE</h3>
        </div>
      </div>
       <div>
        {isDataEmpty ? (
          <div className={style.noData}>
            <Empty description={
               <span>
               No product page visitors statistics
             </span>
            } />
          </div>
        ) : (
          <PieChartStats data={chartData} COLORS={COLORS} label={`${parseFloat(percentageOfUsersRoutedToProducts).toFixed(2)} % `} labelTitle=" Routed to product page" />
        )}
      </div>
      <div className={style.statItem}>
        <div>
          <h1>{chartData[0].value} 
            <span style={{ backgroundColor: COLORS[0], width: '20px', height: '26px', display: 'inline-block', marginLeft: '10px',borderRadius:'4px' }}></span>
          </h1>
          <h3 className={style.chartHeader}>{chartData[0].name}</h3>
        </div>
        <div>
          <h1>{chartData[1].value} 
            <span style={{ backgroundColor: COLORS[1], width: '20px', height: '26px', display: 'inline-block', marginLeft: '10px',borderRadius:'4px' }}></span>
          </h1>
          <h3 className={style.chartHeader}>{chartData[1].name}</h3>
        </div>
      </div>
    </div>
  );
};

export default ProductSearchAnalytics;
