import React, { useEffect, useRef, useState } from 'react';
import style from './QueriesList.module.scss';
import { Table, Tag, Tooltip, Button } from 'antd';
import { LeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { CSVLink } from 'react-csv'; // Import CSVLink from react-csv
import ExportCSVButton from './exportCSVButton';
import Markdown from 'markdown-to-jsx';
import useFetchCSVData from '../hooks/useFetchCSVData';

// Function to check if a query contains "Contact us"
const isContactUsQuery = (text) => {
  return text.includes("Contact us");
};

const columns = (onQueryClick) => [
  {
    title: 'Inquiries',
    dataIndex: 'type',
    key: 'inquiry',
    responsive: ['xs', 'sm', 'md', 'lg'],
    ellipsis: true,
    render: (text, record) => {
      const color = record.type === 'product-related' ? '#E6ECEE' : '#EDEEE6';

      return (
        !isContactUsQuery(record.query) && (
          <Tooltip color={color} title={`Query: ${record.query}`} overlayInnerStyle={{ color: 'black' }}>
            <span
              style={{ color: 'black', cursor: 'pointer' }}
              onClick={() => onQueryClick(record)}
            >
              Query: <Markdown>{record.query}</Markdown>
              <br />
              <Tag style={{ color: 'black', marginTop: "0.5em" }} color={color}>
                {record.type}
              </Tag>
            </span>
          </Tooltip>
        )
      );
    }
  },
  {
    title: 'No. of times triggered',
    dataIndex: 'count',
    key: 'count',
    responsive: ['xs', 'sm', 'md', 'lg'],
  },
];

const TopQueries = ({ data, seletedTimeRange }) => {
  const { loading, fetchCSVData, FetchFullTranscript } = useFetchCSVData({seletedTimeRange});
  const [selectedQuery, setSelectedQuery] = useState(null);

  const handleQueryClick = (query) => {
    setSelectedQuery(query);
  };

  const handleBackToList = () => {
    setSelectedQuery(null);
  };

  // Prepare CSV data for selected query with each response in a separate column
  const singleQueryCSVData = selectedQuery && !isContactUsQuery(selectedQuery.query) ? [
    ['Query', ...selectedQuery.responses.map((_, index) => `Response ${index + 1}`)],
    [
      selectedQuery.query,
      ...selectedQuery.responses
    ]
  ] : [];

  return (
    <div className={style.queryList}>
      {selectedQuery ? (
        <div style={{ maxHeight: '450px', overflowY: 'scroll', minHeight: '450px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h2 className={style.chartHeader}>
              <LeftOutlined
                style={{ cursor: 'pointer', marginRight: '5px' }}
                onClick={handleBackToList}
              />
              Queries and Responses
            </h2>
            <ExportCSVButton
              data={singleQueryCSVData}
              filename="single_query_and_responses.csv"
              label="Export Query (CSV)"
            />

          </div>
          <h3>Query: {selectedQuery.query}</h3>
          <p style={{ marginTop: 10 }}><strong>Responses:</strong></p>
          <div style={{ marginRight: "1em" }}>
            {selectedQuery.responses.length > 0 ? (
              selectedQuery.responses.map((response, index) => (
                <div key={index} className={style.card}>
                  <Markdown>{response}</Markdown>
                </div>
              ))
            ) : (
              <p>No responses available.</p>
            )}
          </div>
        </div>
      ) : (
        <div style={{ maxHeight: '450px', overflowY: 'scroll' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>

            <h2 className={style.chartHeader}>Top Queries Raised with Chatbot</h2>
            <div style={{marginBottom: "0.5em"}}>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={fetchCSVData}
                style={{ marginRight: '10px', backgroundColor: '#055b7f' }}
              >
                    Export User Logs
              </Button>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={FetchFullTranscript}
                style={{ marginRight: '10px', backgroundColor: '#055b7f' }}
              >
                  Full Export
              </Button>
            </div>
          </div>
          <Table
            columns={columns(handleQueryClick)}
            dataSource={data.filter(record => !isContactUsQuery(record.query))}
            pagination={false}
          />
        </div>
      )}
    </div>
  );
};

export default TopQueries;
